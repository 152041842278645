import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import Events from "../../components/Events";

const Events22Page = () => {
    return (
        <Layout pageTitle="2022 Events | LUTH">
            <NavOne />
            <PageHeader title="" />
            <Events year = '2022' />
            <Footer />
        </Layout>
    );
};

export default Events22Page;
